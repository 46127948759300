import http from "./http"
const newUrl = 'https://pay.gxb.cn/api'

//银行卡列表
export const $apiBankList = (data) => http({ method: "post", url: newUrl+"/bank/list", data })
//注销银行卡
export const $apilogOutBank = (data) => http({ method: "post", url: newUrl+"/replace/bank", data })
//绑定多个账户
export const $apiMultipleBind = (data) => http({ method: "post", url: newUrl+"/multiple/bind", data })
//新增银行卡
export const $apiAddBank = (data) => http({ method: "post", url: newUrl+"/bank/card/binding", data })
//新增银行卡验证
export const $apiAddBankVerify = (data) => http({ method: "post", url: newUrl+"/binding/verify", data })
//解除绑定银行卡
export const $apiUnbindingBank = (data) => http({ method: "post", url: newUrl+"/unbinding/card", data })


