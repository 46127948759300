// => 过滤银行卡背景色
let redBank = ['中国', '工商', '招商', '华夏', '广发', '中信', '南京', '浙商']
let blueBank = ['建设', '交通']
let yellowBank = ['光大', '平安']
let greenBank = ['农业', '民生', '邮政储蓄', '杭州']

const bank = [
  '建设',
  '交通',
  '光大',
  '平安',
  '农业',
  '民生',
  '邮政储蓄',
  '杭州',
  '中国',
  '工商',
  '招商',
  '华夏',
  '广发',
  '中信',
  '南京',
  '浙商'
]

export function filterBank(name) {
  if (name.includes('中国')) {
    name = String(name).replace('中国', '')
  }
  const logo = String(name).replace('银行', '')
  let bg = ''
  if (redBank.includes(logo)) {
    bg = 'background: linear-gradient(-45deg, #E65660 0%, #F96D77 100%);'
  } else if (blueBank.includes(logo)) {
    bg = 'background: linear-gradient(-45deg, #5076CB 1%, #5283E5 100%);'
  } else if (yellowBank.includes(logo)) {
    bg = 'background: linear-gradient(-45deg, #F58E49 1%, #FFBA33 100%);'
  } else if (greenBank.includes(logo)) {
    bg = 'background: linear-gradient(-45deg, #3BA380 1%, #44BB93 100%);'
  } else {
    bg = 'background: linear-gradient(-45deg, #4E5978 1%, #5B6483 100%);'
  }
  return {
    logo: `https://gangxinbao.cn/assets/images/bank-logo/${
      bank.includes(logo) ? logo : '暂无图片'
    }.png`,
    bg: bg
  }
}